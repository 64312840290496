import { Cart as CartType } from "@medusajs/medusa";
import { OnApproveActions, OnApproveData } from "@paypal/paypal-js";
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import * as Sentry from "@sentry/nextjs";
import { useCart, useCreatePaymentSession, useDeletePaymentSession, useSetPaymentSession, useUpdateCart } from "medusa-react";
import React, { useEffect, useRef, useState } from "react";

import { medusaClient } from "@/lib/config";
import { useLoadingRouter } from "@/lib/contexts/loading-context";
import { useSideModal } from "@/lib/contexts/sidemodal-context";
import { useStore } from "@/lib/contexts/store-context";
import Spinner from "@/modules/common/icons/spinner";

import { ConfirmData, useOnConfirm } from "../../overview";

const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID || "";
const PAYPAL_EXPRESS_CHECKOUT_ACTIVATED = process.env.PAYPAL_EXPRESS_CHECKOUT_ACTIVATED || false;

const IsLoading = () => {
  const [{ isPending }] = usePayPalScriptReducer();

  return isPending ? <Spinner /> : null;
};

export const PaypalExpressButton = ({ className }: { className?: string }) => {
  const { cart, setCart } = useCart();
  const cartRef = useRef(cart);
  // const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { mutateAsync: createPaymentSessions } = useCreatePaymentSession(cart?.id || "");
  const { mutateAsync: setPaymentSession } = useSetPaymentSession(cart?.id || "");
  const { mutateAsync: deletePaymentSession } = useDeletePaymentSession(cart?.id || "");
  const { mutateAsync: updateCart } = useUpdateCart(cart?.id || "");
  const { setModal } = useSideModal();
  const { push } = useLoadingRouter();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handlePayment = async (data: OnApproveData, actions: OnApproveActions) => {
    const order = await actions.order?.get();

    const purchase = order?.purchase_units?.find((pu) => pu.custom_id === cart?.id);
    const payer = order?.payer;
    if (!purchase) {
      throw new Error("Missing purchase unit");
    }
    if (!payer) {
      throw new Error("Missing payer");
    }

    let currentCart = cart;
    const shippingAddress = {
      first_name: payer.name?.given_name,
      last_name: payer.name?.surname,
      ...((purchase.shipping?.address?.address_line_1 && splitAddress(purchase.shipping?.address?.address_line_1)) || {}),
      country_code: purchase.shipping?.address?.country_code?.toLowerCase(),
      postal_code: purchase.shipping?.address?.postal_code,
      province: purchase.shipping?.address?.admin_area_1,
      city: purchase.shipping?.address?.admin_area_2,
    };
    currentCart = (
      await updateCart({
        email: payer.email_address,
        shipping_address: shippingAddress,
        billing_address: shippingAddress,
      })
    ).cart;

    currentCart = (
      await medusaClient.carts.updatePaymentSession(cart?.id ?? "", "paypal", {
        data: {
          data,
        },
      })
    ).cart;

    setCart(currentCart);

    push(`/checkout/review`);
    setModal({ isShow: false, name: "" });
  };
  const createOrder = async () => {
    let currentCart = cartRef.current as CartType;
    if (!cart?.payment_sessions.length) {
      currentCart = (await createPaymentSessions()).cart as CartType;
    }
    if (currentCart.payment_session?.provider_id !== "paypal") {
      currentCart = (await setPaymentSession({ provider_id: "paypal" })).cart as CartType;
    }
    if (currentCart !== cartRef.current) {
      setCart(currentCart);
    }
    return currentCart?.payment_session?.data.id as string;
  };

  if (!PAYPAL_CLIENT_ID || !PAYPAL_EXPRESS_CHECKOUT_ACTIVATED) {
    return null;
  }
  return (
    <div className={className}>
      <PayPalScriptProvider
        options={{
          clientId: PAYPAL_CLIENT_ID,
          currency: "EUR",
          intent: "authorize",
          dataPageType: "cart",
          commit: false,
        }}
      >
        {errorMessage && <span className="text-rose-500 mt-4">{errorMessage}</span>}
        <IsLoading />
        <PayPalButtons
          style={{ layout: "horizontal", label: undefined, tagline: false, color: "white" }}
          createOrder={createOrder}
          onApprove={handlePayment}
          onError={(error) => {
            // @todo reicht scheinbar noch nicht, abgelaufene Paypal Session failed weiterhin
            deletePaymentSession({
              provider_id: "paypal",
            }).finally(() => {
              console.error(error);
              setErrorMessage("An error occurred. Please try again.");
            });
          }}
          // onCancel={() => {
          //   // @todo payment session löschen
          // }}
          // disabled={notReady || submitting}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export const PayPalPaymentButton = ({ notReady, confirm, failedMessage }: { notReady: boolean; confirm: ConfirmData; failedMessage?: string }) => {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { cart } = useCart();
  const { resetCart } = useStore();
  const { push } = useLoadingRouter();
  const onConfirm = useOnConfirm();

  const confirmData = useRef(confirm);
  confirmData.current = confirm; // Workaround: Der handlePaymentCallback wird vom Paypal-Button nicht geupdatet

  useEffect(() => {
    medusaClient.carts.setPaymentSession(cart?.id ?? "", {
      provider_id: "paypal",
    });
  }, [cart?.id]);

  const handlePayment = async (_data: OnApproveData, actions: OnApproveActions) => {
    actions?.order
      ?.authorize()
      .then(async (authorization) => {
        if (authorization.status !== "COMPLETED") {
          Sentry.captureException(failedMessage ?? `An error occurred, status: ${authorization.status}`);
          setErrorMessage(failedMessage ?? `An error occurred, status: ${authorization.status}`);
          return;
        }

        await onConfirm(confirmData.current);

        await medusaClient.carts.updatePaymentSession(cart?.id ?? "", "paypal", {
          data: {
            data: {
              ...authorization,
            },
          },
        });

        const { data } = await medusaClient.carts.complete(cart?.id ?? "");
        resetCart(true);
        push(`/order/confirmed/${data.id}`);
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            failedMessage,
          },
        });
        setErrorMessage(failedMessage ?? `Unbekannter Fehler. Bitte probieren Sie es erneut.`);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <PayPalScriptProvider
      options={{
        clientId: PAYPAL_CLIENT_ID,
        currency: "EUR",
        intent: "authorize",
        locale: "de_DE",
      }}
    >
      {errorMessage && <span className="text-rose-500 mt-4">{errorMessage}</span>}
      <IsLoading />
      <PayPalButtons
        style={{ layout: "horizontal", label: "pay", tagline: false, color: "white" }}
        createOrder={async () => cart?.payment_session?.data.id as string}
        onApprove={handlePayment}
        disabled={notReady || submitting}
      />
    </PayPalScriptProvider>
  );
};

export function splitAddress(street: string) {
  const match = street.match(/^(\D.*?)\s*(\d{1,10}(?:\s?[/-]\s?\d{0,8})?\s?[a-z]?)$/i);
  if (match) {
    return {
      address_1: match[1],
      address_2: match[2],
    };
  }
  return undefined;
}
