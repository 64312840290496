import { Cart, PaymentSession } from "@medusajs/medusa";
import * as Sentry from "@sentry/nextjs";
import { AxiosError } from "axios";
import React, { useCallback } from "react";

import { useCheckout } from "@/lib/contexts/checkout-context";
import PaymentContainer from "@/modules/checkout/components/Payments/payment-container";

import { payment_config } from "../../../../lib/config";
import { ConfirmData, SubmitButton } from "../../overview";
import { AmazonPayButton } from "./payment-amazonpay";
import { PayPalPaymentButton } from "./payment-paypal";

export type SubmitCallback = () => Promise<boolean>;
export type SetSubmitCallback = (cb: SubmitCallback | null) => void;

interface PaymentsInterface {
  cart: Cart;
  setSubmitCallback: SetSubmitCallback;
  handleComplete: () => void;
}

const CLUSTER_PAY_NOW = ["paypal"];
const CLUSTER_PAY_LATER = ["klarna-payments", "commdoo_santander_invoice"];
const CLUSTER_INSTALLMENT_PURCHASE = ["commdoo_santander_instalment"];

const checkAvailability = (cart: Cart, paymentSession: PaymentSession) => {
  if (paymentSession.provider_id == "paypal") {
    return false;
  }

  const config = payment_config[paymentSession.provider_id] ?? {};
  if (config.minTotal && (cart.total || 0) < config.minTotal * 100) {
    return false;
  }
  return true;
};

export const Payments = ({ cart, setSubmitCallback, handleComplete }: PaymentsInterface) => {
  const { setPaymentSession } = useCheckout();
  const [paymentSelectionError, setPaymentSelectionError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const selectPayment = useCallback(
    async (providerId: string) => {
      setIsLoading(true);
      try {
        await setPaymentSession(providerId);
        setPaymentSelectionError(null);
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            provider_id: providerId,
          },
        });
        if ((e as AxiosError).response?.data?.message) {
          console.error("Error selecting payment", (e as AxiosError).response!.data);
        }

        setPaymentSelectionError(
          "Wir konnten Ihre Zahlungsmethode nicht authentifizieren. Probieren Sie es bitte zu einem späteren Zeitpunkt oder versuchen Sie es mit einer anderen Zahlungsmethode.",
        );
      } finally {
        setIsLoading(false);
      }
    },
    [setPaymentSession, setPaymentSelectionError],
  );

  const payment_sessions = cart.payment_sessions
    .filter((paymentSession) => checkAvailability(cart, paymentSession))
    .sort((a, b) => (a.provider_id > b.provider_id ? 1 : -1));

  const payNowPaymentSessions = payment_sessions.filter(
    (paymentSession) =>
      CLUSTER_PAY_NOW.includes(paymentSession.provider_id) ||
      (!CLUSTER_PAY_LATER.includes(paymentSession.provider_id) && !CLUSTER_INSTALLMENT_PURCHASE.includes(paymentSession.provider_id)),
  );
  const payLaterPaymentSessions = payment_sessions.filter((paymentSession) => CLUSTER_PAY_LATER.includes(paymentSession.provider_id));
  const installmentPaymentSessions = payment_sessions.filter((paymentSession) => CLUSTER_INSTALLMENT_PURCHASE.includes(paymentSession.provider_id));

  return (
    <>
      {paymentSelectionError && <p className="text-center text-red-500">{paymentSelectionError}</p>}

      {payNowPaymentSessions.length > 0 && (
        <PaymentCluster title={"Jetzt zahlen"} isLoading={isLoading}>
          {payNowPaymentSessions
            .filter((paymentSession) => paymentSession.provider_id !== "amazonpay")
            .map((paymentSession) => (
              <PaymentContainer
                paymentSession={paymentSession}
                key={paymentSession.id}
                selected={cart?.payment_session?.provider_id === paymentSession.provider_id}
                setSelected={() => !isLoading && selectPayment(paymentSession.provider_id)}
                setSubmitCallback={setSubmitCallback}
                handleComplete={handleComplete}
              />
            ))}
        </PaymentCluster>
      )}

      {payLaterPaymentSessions.length > 0 && (
        <PaymentCluster title={"Später zahlen"} isLoading={isLoading}>
          {payLaterPaymentSessions.map((paymentSession) => (
            <PaymentContainer
              paymentSession={paymentSession}
              key={paymentSession.id}
              selected={cart?.payment_session?.provider_id === paymentSession.provider_id}
              setSelected={() => !isLoading && selectPayment(paymentSession.provider_id)}
              setSubmitCallback={setSubmitCallback}
              handleComplete={handleComplete}
            />
          ))}
        </PaymentCluster>
      )}

      {installmentPaymentSessions.length > 0 && (
        <PaymentCluster title={"In Raten zahlen"} isLoading={isLoading}>
          {installmentPaymentSessions.map((paymentSession) => (
            <PaymentContainer
              paymentSession={paymentSession}
              key={paymentSession.id}
              selected={cart?.payment_session?.provider_id === paymentSession.provider_id}
              setSelected={() => !isLoading && selectPayment(paymentSession.provider_id)}
              setSubmitCallback={setSubmitCallback}
              handleComplete={handleComplete}
            />
          ))}
        </PaymentCluster>
      )}
    </>
  );
};

interface PaymentClusterInterface {
  title: string;
  isLoading: boolean;
  children: React.ReactNode;
}

const PaymentCluster = ({ title, isLoading, children }: PaymentClusterInterface) => {
  return (
    <div className="mb-10">
      <h1 className="font-thin uppercase text-2xl text-center mb-5">{title}</h1>

      <div className={`transition-all ${isLoading ? "blur-sm" : ""}`}>{children}</div>
    </div>
  );
};

const failedMessage =
  "Wir konnten ihre Zahlungsmethode nicht authentifizieren. Probieren Sie es bitte zu einem späteren Zeitpunkt oder versuchen Sie es mit einer anderen Zahlungsmethode.";

export const PaymentConfirmButton = ({ cart, valid, confirm }: { cart: Cart; valid: boolean; confirm: ConfirmData }) => {
  const isReady = cart?.shipping_address && cart?.billing_address && cart?.email && cart?.shipping_methods;

  if (!cart.payment_session) {
    return null;
  }

  switch (cart.payment_session.provider_id) {
    case "paypal":
      return <PayPalPaymentButton notReady={!isReady || !valid} confirm={confirm} failedMessage={failedMessage} />;
    case "amazonpay":
      return <AmazonPayButton paymentSession={cart.payment_session} valid={valid} confirm={confirm} failedMessage={failedMessage} />;
    default:
      return <SubmitButton valid={valid} confirm={confirm} failedMessage={failedMessage} />;
  }
};
